<!--
 * @Description: 回访类型配置
 * @Author: ChenXueLin
 * @Date: 2021-10-20 13:50:00
 * @LastEditTime: 2022-07-15 15:50:47
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="code">
              <e6-vr-select
                v-model="searchForm.code"
                :data="visiteTypeList"
                placeholder="回访类型"
                title="回访类型"
                clearable
                :props="{
                  id: 'code',
                  label: 'value'
                }"
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="40"
            :selectable="checkSelectable"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      v-dialogDrag
      :title="type == 'edit' ? '编辑回访类型' : '添加回访类型'"
      :visible.sync="editDialog"
      width="550px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="editDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="addTypeForm"
        :model="addTypeForm"
        :rules="addTypeFormRules"
        lbel-width="120px"
      >
        <el-form-item label="回访类型名称" prop="value">
          <el-input
            v-model.trim="addTypeForm.value"
            placeholder="回访类型名称"
            style="width:300px;"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getVisiteTypeList,
  delVisiteType,
  addVisiteType,
  editVisiteType,
  getVisiteType
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "visiteType",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      loading: false,
      visiteTypeList: [], //回访类型下拉框
      total: 0,
      searchForm: {
        configId: "", //回访ID
        createdBy: "", //创建人
        code: "", //回访类型
        createTime: [],
        beginTimeTimestamp: "", //开始时间
        endTimeTimestamp: "", //结束时间
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "configId",
          display: true,
          fieldLabel: "回访类型ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "value",
          display: true,
          fieldLabel: "回访类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "questionNum",
          display: true,
          fieldLabel: "问题数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeString",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: [], //选中的数据
      //***新增 */
      type: "add",
      addTypeFormRules: {
        value: [
          {
            required: true,
            message: "请输回访类型名称",
            trigger: ["blur", "change"]
          }
        ]
      },
      addTypeForm: {
        value: "" //回访类型名称
      },
      editDialog: false,
      editDialogLoading: false,
      refTable: "tableList",
      queryListAPI: getVisiteTypeList
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTimeTimestamp = val[0] || "";
        this.searchForm.endTimeTimestamp = val[1] || "";
      }
    }
  },
  created() {
    this.getVisiteType();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //问题数为0才能删除
    checkSelectable(row) {
      return row.questionNum == 0;
    },
    //查询回访类型下拉框
    async getVisiteType() {
      try {
        this.loading = true;
        let res = await getVisiteType();
        this.visiteTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.configId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //批量删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteType();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除客户请求
    async deleteType() {
      try {
        this.loading = true;
        let res = await delVisiteType({ ids: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
          this.getVisiteType();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.editDialog = true;
        this.type = "edit";
        this.addTypeForm = _.cloneDeep(row);
      }
    },
    /******新增/编辑弹框***********/
    //添加类型
    handleAdd() {
      this.editDialog = true;
      this.type = "add";
    },
    //点击确定新增类型
    handleSubmit() {
      this.$refs.addTypeForm.validate(valid => {
        if (valid) {
          if (this.type == "edit") {
            this.editReq();
          } else {
            this.addReq();
          }
        }
      });
    },

    //新增类型请求
    async addReq() {
      try {
        let res = await addVisiteType(this.addTypeForm);
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.queryList();
          this.getVisiteType();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //编辑类型请求
    async editReq() {
      try {
        let res = await editVisiteType(this.addTypeForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.queryList();
          this.getVisiteType();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addTypeForm = {
        value: ""
      };
      this.$refs.addTypeForm.resetFields();
      this.editDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .block-form .el-form-item__label {
      float: none;
    }
    .options-content {
      display: block;
      width: 100%;
      margin-left: 15px;
      .options {
        font-size: 12px;
        color: #606266;
        display: flex;
        align-items: center;
        margin-top: 10px;
        i {
          margin-left: 10px;
          height: 28px;
          margin-right: 10px;
          color: #46bfea;
          cursor: pointer;
        }
        [class^="e6-icon"] {
          font-size: 20px;
        }
        .el-input {
          width: 350px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
